// @ts-nocheck
import {Column} from 'react-table'
import {
  UserInfoCell,
  // UserActionsCell,
  UserSelectionCell,
  UserCustomHeader,
  UserSelectionHeader,
} from '../../../../../../_template/layout/components/columns'
import {FormattedMessage} from 'react-intl'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {CustomOrderStatus} from './customStatus'
import {OrderActionsCell} from '../../../../../../_template/layout/components/columns/OrderActionsCell'
import {OrderAddressCell} from '../../../../../../_template/layout/components/columns/OrderAddressCell'
import {Link} from 'react-router-dom'
// import {FirstCell} from '../../../../../../_template/layout/components/columns/FirstCell'

const ordersColumns: ReadonlyArray<Column<IOrder>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='ECOMMERCE.COMMON.ORDER.REFERENCE' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'reference',
  //   Cell: ({...props}) => <FirstCell value={'#' + props.data[props.row.index].reference} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.SELLER' />}
        className='min-w-125px'
      />
    ),
    Cell: ({...props}) => {
      const {sellers} = props?.data[props.row.index]
      return (
        <div className='d-flex gap-1'>
          <div>{sellers?.shopName}</div>
        </div>
      )
    },

    id: 'sellers',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.SELLERS.ADDRESS' />}
        className='min-w-200px'
      />
    ),
    id: 'sellers.address',
    Cell: ({...props}) => <OrderAddressCell order={props.data[props.row.index].sellers} />,
    // accessor: 'customer',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.TOTAL' />}
        className='min-w-125px'
      />
    ),
    id: 'totalAmount',
    Cell: ({...props}) => <div>{Number(props?.data[props.row.index]?.totalAmount).toFixed(2)}</div>,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.PAYMENTMETHOD' />}
        className='min-w-200px'
      />
    ),
    accessor: 'paidThrough',
    // Cell: ({...props}) => <OrderAddressCell order={props.data[props.row.index]} />,
    // accessor: 'customer',
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.DRIVER' />}
        className='min-w-125px'
      />
    ),
    id: 'driver',
    Cell: ({...props}) => {
      const {driver} = props?.data[props.row.index]
      return driver ? (
        <Link to={`/drivers/${driver._id}`}>
          <div className='d-flex gap-1'>
            <div>{driver?.firstName}</div>
            <div>{driver?.lastName}</div>
          </div>
        </Link>
      ) : (
        <div><FormattedMessage id={'ECOMMERCE.ORDER.DRIVER.NOT.ASSIGNED'}/></div>
      )
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ODOO_ID' />}
        className='min-w-80px'
      />
    ),
    accessor: 'odooID',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.STATUS' />}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <CustomOrderStatus status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <OrderActionsCell order={props.data[props.row.index]} id={props.data[props.row.index]._id} />
    ),
  },
]

export {ordersColumns}
