// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from '../../../../../../_template/layout/components/columns'
import {FormattedMessage} from 'react-intl'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {CustomOrderStatus} from './customStatus'
import {OrderAddressCell} from '../../../../../../_template/layout/components/columns/OrderAddressCell'
import {FirstCell} from '../../../../../../_template/layout/components/columns/FirstCell'
import {MainOrderActionsCell} from '../../../../../../_template/layout/components/columns/MainOrderActionsCell'
import {Link} from 'react-router-dom'
import { FirstCellLink } from '../../../../../../_template/layout/components/columns/FirstCellLink'

const mainOrdersColumns: ReadonlyArray<Column<IMainOrder>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.REFERENCE' />}
        className='min-w-125px'
      />
    ),
    id: 'reference',
    Cell: ({...props}) => (
      <Link
        style={{color: 'blue'}}
        className='px-3'
        to={`/main-orders/${props.data[props.row.index]._id}/orders`}
        data-bs-toggle="tooltip" data-bs-placement="top" title="Go to Sub-Orders"
      >
        <FirstCellLink value={'#' + props.data[props.row.index].reference} />
      </Link>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CUSTOMER' />}
        className='min-w-125px'
      />
    ),
    Cell: ({...props}) => {
      const {customer} = props?.data[props.row.index]
      return (
        <div className='d-flex gap-1'>
          <div>{customer?.firstName}</div>
          <div>{customer?.lastName}</div>
        </div>
      )
    },

    id: 'customer',
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.TOTAL' />}
        className='min-w-125px'
      />
    ),
    id: 'totalAmount',
    Cell: ({...props}) => <div>{Number(props?.data[props.row.index]?.totalAmount).toFixed(2)}</div>,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.PAYMENTMETHOD' />}
        className='min-w-200px'
      />
    ),
    accessor: 'paidThrough',
    // Cell: ({...props}) => <OrderAddressCell order={props.data[props.row.index]} />,
    // accessor: 'customer',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ADDRESS' />}
        className='min-w-200px'
      />
    ),
    id: 'customer.address',
    Cell: ({...props}) => <OrderAddressCell order={{address:props.data[props.row.index].shippingAddress}} />,
    // accessor: 'customer',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.SUBORDERS_NUMBER' />}
        className='min-w-125px'
      />
    ),
    id: 'subOrders',

    Cell:  ({...props}) => <>{props.data[props.row.index]?.subOrders?.length} </>,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ODOO_ID' />}
        className='min-w-80px'
      />
    ),
    accessor: 'odooID',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.STATUS' />}
        className='min-w-125px'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <CustomOrderStatus status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <MainOrderActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {mainOrdersColumns}
