// @ts-nocheck
import {Column} from 'react-table'
import {
  UserInfoCell,
  // UserTwoStepsCell,
  // UserSelectionCell,
  UserCustomHeader,
  UserSelectionHeader,
} from '../../../../../../_template/layout/components/columns'

import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import {CustomerActionsCell} from '../../../../../../_template/layout/components/columns/CustomerActionsCell'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import { Link } from 'react-router-dom'

const customerColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.NAME' />}
        className='min-w-125px'
      />
    ),
    id: 'name',
    Cell: ({...props}) => (
      <Link to={`/customers/${props.data[props.row.index]._id}`}>
        <UserInfoCell user={props.data[props.row.index]} />
      </Link>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ADDRESS' />}
        className='min-w-125px'
      />
    ),
    accessor: 'address.city',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.PHONENUMBER' />}
        className='min-w-125px'
      />
    ),
    accessor: 'phoneNumber',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.EMAIL' />}
        className='min-w-125px'
      />
    ),
    accessor: 'email',
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='ECOMMERCE.COMMON.STATUS' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'status',
  //   Cell: ({...props}) => <UserTwoStepsCell two_steps={1} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ODOO_ID' />}
        className='min-w-80px'
      />
    ),
    accessor: 'odooID',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),

    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <CustomerActionsCell id={props.data[props.row.index]._id} />,
  },
]

export default customerColumns
