// @ts-nocheck
import {Column} from 'react-table'
import {
  // UserInfoCell,
  // UserSelectionCell,
  UserCustomHeader,
  // UserSelectionHeader,
} from '../../../../../../_template/layout/components/columns'

import {User} from '../../core/_models'
import {FormattedMessage} from 'react-intl'
import {SellerActionsCell} from '../../../../../../_template/layout/components/columns/SellerActionsCell'
import {DateCell} from '../../../../../../_template/layout/components/columns/DateAnTimeCell'
import {FirstCell} from '../../../../../../_template/layout/components/columns/FirstCell'
import { Link } from 'react-router-dom'

const usersColumns: ReadonlyArray<Column<User>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.NAME' />}
        className='min-w-125px'
      />
    ),
    id: 'shopName',
    Cell: ({...props}) =><Link to={`/sellers/${props.data[props.row.index]._id}`}><FirstCell value={props.data[props.row.index].shopName} /></Link>  ,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CITY' />}
        className='min-w-125px'
      />
    ),
    accessor: 'address.city',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.PHONENUMBER' />}
        className='min-w-125px'
      />
    ),
    accessor: 'phoneNumber',
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title={<FormattedMessage id='ECOMMERCE.COMMON.ACTIVITYFIELD' />}
  //       className='min-w-125px'
  //     />
  //   ),
  //   id: 'activityField',
  //   accessor: 'activityField',
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.ODOO_ID' />}
        className='min-w-80px'
      />
    ),
    accessor: 'odooID',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ECOMMERCE.COMMON.CREATIONDATE' />}
        className='min-w-80px'
      />
    ),
    id: 'createDate',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createDate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title={<FormattedMessage id='MENU.ACTIONS' />}
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <SellerActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {usersColumns}
